"use strict";

import Swal from "sweetalert2";

/**
 * Pause execution of a function. Useful when polling, etc.
 * @param duration Length of pause in seconds.
 */
function sleep(duration: number) {
    return new Promise((resolve) => setTimeout(resolve, duration));
}

const apiUrl = "/api/v1";
const jobsApiUrl = `${apiUrl}/jobs`;
const lastJobUrl = `${jobsApiUrl}/last`;
const csrfTokenEl = document.querySelector("[name=csrfmiddlewaretoken]") as HTMLInputElement | null
if (!csrfTokenEl) {
    throw "No csrf token element"
}
const csrfToken = csrfTokenEl.value;
if (!csrfToken) {
    throw "No csrf token value"
}

function makeUrl(relativeUrl, params={}) {
    let url = new URL(relativeUrl, window.origin)
    if (params) {
        url.search = new URLSearchParams(params).toString()
    }
    return url;
}

function makeJobCancelUrl(jobId) {
    return makeUrl(`${jobsApiUrl}/${jobId}/cancel`)
}

interface Model {
    projectId?: string,
    jobId?: string,
    jobStatus?: string,
    alertDisplayed: Boolean,  // The alert is front-end only
    statusBarDisplayed: Boolean,  // The status may be created server-side
}

let model: Model = {
    alertDisplayed: false,
    statusBarDisplayed: true,
};

async function post(url, params={}) {
    params.method = "POST";
    params.mode = "same-origin" // Do not send CSRF token to another domain.
    params.headers = { "X-CSRFToken": csrfToken }
    return fetch(url.toString(), params)
}

async function get(url) {
    return fetch(
        url.toString(),
        {
            method: "GET",
            mode: "same-origin",
        }
    );
}

async function startJob(event) {
    event.preventDefault();
    let forceHourlySelector = document.getElementById("force-hourly-selector");
    let forceHourly;
    if (forceHourlySelector) {
        forceHourly = (forceHourlySelector.value === "true") ? true : false;
    } else {
        forceHourly = false;
    }
    $("#confirmSettingsModal").modal('hide');
    let calculateButton = document.querySelector("#calculate-btn") as HTMLButtonElement | null
    if (!calculateButton) {
        throw "No calculate button"
    }
    let projectId = calculateButton.dataset.project_id;
    if (!projectId) {
        throw "No project id"
    }
    let url = makeUrl(jobsApiUrl, {project_id: projectId, force_hourly: forceHourly});
    try {
        let response = await post(url)
        let result = await response.json();
        model.jobId = result.jobId;
        model.jobStatus = result.jobStatus;
        Swal.fire({
            position: "center",
            title: "Calculating optimal system!",
            showConfirmButton: false,
            timer: 2000,
            icon: "success",
        });
        let lockableElements = document.getElementsByClassName("lockable");
        for (let lockableElement of lockableElements) {
            if (lockableElement.classList.contains("btn")) {
                lockableElement.setAttribute("disabled", "disabled");
            } else {
                lockableElement.classList.add("disabled");
            }
        }
    } catch (error) {
        console.log(error)
        Swal.fire({
            position: "center",
            title: "Error",
            text: "Unable to start optimization",
            showConfirmButton: true,
            icon: "error",
        });
    }
}

async function cancelJob(event) {
    event.preventDefault();
    let cancelButton = event.target as HTMLLinkElement;
    let jobId = cancelButton.dataset.job_id;
    if (!jobId) {
        throw "No job id"
    }
    try {
        let url = makeJobCancelUrl(jobId)
        let response = await post(url);
        let result = await response.json();
        // Deactivate the cancel button and change the job status text to Cancelling
        cancelButton.setAttribute("disabled", "disabled");
        var jobStatusTitle = document.getElementById("job-status-title");
        jobStatusTitle.textContent = "Cancelling\xa0";
        var jobStatusHeader = document.getElementById("job-status-header");
        jobStatusHeader.innerHTML =
            "Cancelling optimization job:</br>" + result.project_name;
    } catch (error) {
        // TODO: Handle this error
        console.log(error);
    }
}

function markJobAsViewed(jobId, resultsUrl, stay) {
    let url = makeUrl(`${jobsApiUrl}/${jobId}/viewed`);
    post(url)
    .then((response) => response.json())
    .then((result) => {
        if (result.success === true) {
            // We're done
        } else {
            // There's no other possible response right now, so we're done
        }
    })
    .catch((error) => {
        // TODO: Handle this error
        console.log(error);
    })
    .finally(() => {
        if (stay) {
            // The user wants to stay where they are.
            // If they're already on the results page,
            // reload so we can see the results.
            if (window.location.pathname === resultsUrl) {
                window.location.href = window.location.href+"?reload=true"
            }
        } else {
            // The user want to switch to the results page.
            // If they're already on the results page,
            // reload so we can see the results.
            if (window.location.pathname === resultsUrl) {
                window.location.href = window.location.href+"?reload=true"
            } else {
                // Switch to the results page.
                window.location.href = resultsUrl;
            }
        }   
    });
}

/**
 * Show the status bar and create the relevant project links.
 * @param state
 * @param result
 */
function displayJobStatus(state, result) {
    let statusBar = state.navbarJobStatus;
    if (elementIsHidden(statusBar)) {
        // The job status indicator is hidden, so we need to show it
        state.cancelButton.dataset.job_id = result.job_id;
        state.projectLink.setAttribute("href", result.project_url);
        state.jobStatusHeader.innerHTML =
            "Current optimization job:</br> " + result.project_name;
        statusBar.classList.remove("d-none");
    }
    model.statusBarDisplayed = true;
    return state;
}

/**
 * Hide the status bar
 * @param state
 */
function hideJobStatus(state) {
    let statusBar = state.navbarJobStatus;
    if (!statusBar.classList.contains("d-none")) {
        state.projectLinkEl.setAttribute("href", "#");
        statusBar.classList.add("d-none");
    }
    let calcBtn = document.getElementById("calculate-btn") as HTMLButtonElement | null;
    if (calcBtn) {
        let componentsEl = document.getElementById("components");
        if (componentsEl) {
            if (componentsEl.querySelector("tbody").childElementCount) {
                calcBtn.removeAttribute("disabled");
            }
        }
    }
    var jobStatusTitle = document.getElementById("job-status-title");
    jobStatusTitle.textContent = "Optimizing\xa0";
    model.statusBarDisplayed = false;
}

function elementIsDisplayed(el) {
    return !el.classList.contains("d-none");
}

function elementIsHidden(el) {
    return el.classList.contains("d-none");
}

/**
 * Poll the server and update the UI based on job status.
 * @param state
 */
async function checkJobStatus(state) {
    console.log("launching...")
    while (true) {
        console.log("Polling for job status...");
        let result;
        try {
            let response = await get(lastJobUrl);
            result = await response.json();
        } catch (error) {
            /**
             * If we can't get a valid response,
             * leave the UI unchanged and try again (after waiting)
            */
            await sleep(5000);
            continue
        }
        if (result.status === "cancelled") {
            let projectNameEl = document.getElementById("project-name")
            let projectName = result.project_name
            if (projectNameEl) {
                if (projectNameEl.innerText === projectName) {
                    let resultsButton = document.getElementById("results-btn")
                    if (resultsButton) {
                        resultsButton.removeAttribute("disabled");
                    }
                    let lockableElements = document.getElementsByClassName("lockable");
                    for (let lockableElement of lockableElements) {
                        if (lockableElement.classList.contains("btn")) {
                            lockableElement.removeAttribute("disabled", "disabled");
                        } else {
                            lockableElement.classList.remove("disabled");
                        }
                    }
                }
            }
            model.alertDisplayed = false;
            // Reactivate the cancel button
            state.cancelButton.removeAttribute("disabled");
            hideJobStatus(state);
        } else if (
            result.status === "complete" &&
            result.viewed === false &&
            model.alertDisplayed === false
        ) {
            let jobId = result.job_id;
            let resultsUrl = result.project_url;
            console.log(result)
            let message =
                "Results for " +
                result.project_name +
                " are available now.";
            if (result.system_has_imbalance) {
                message =
                    message +
                    '<br/><br/><div class="alert alert-warning" role="alert">Warning: system has imbalance</div>';
            }
            let projectNameEl = document.getElementById("project-name")
            let projectName = result.project_name
            if (projectNameEl) {
                if (projectNameEl.innerText === projectName) {
                    let resultsButton = document.getElementById("results-btn")
                    if (resultsButton) {
                        resultsButton.removeAttribute("disabled");
                    }
                    let lockableElements = document.getElementsByClassName("lockable");
                    for (let lockableElement of lockableElements) {
                        if (lockableElement.classList.contains("btn")) {
                            lockableElement.removeAttribute("disabled", "disabled");
                        } else {
                            lockableElement.classList.remove("disabled");
                        }
                    }
                }
            }

            Swal.fire({
                position: "center",
                title: "Optimization complete!",
                html: message,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "View results",
                cancelButtonText: "Stay here",
                icon: "success",
            }).then((result) => {
                if (result.isConfirmed) {
                    console.log("confirmed");
                    markJobAsViewed(jobId, resultsUrl, false);
                } else if (result.isDismissed) {
                    console.log("dismissed");
                    markJobAsViewed(jobId, resultsUrl, true);
                }
            });
            model.alertDisplayed = true;
        } else if (
            result.status === "complete" &&
            result.viewed === true &&
            state.alertDisplayed
        ) {
            Swal.close();
        }
        if (
            result.status === "error" &&
            result.viewed === false &&
            model.alertDisplayed === false
        ) {
            const jobId = result.job_id;
            const projectUrl = result.project_url;
            console.log(result)
            console.log("erereergedtgh")
            Swal.fire({
                position: "center",
                title: "Error optimizing " + result.project_name + ".",
                html: '<div class="text-left">' + result.error_msg.replaceAll("\n", "<br/>") + "</div>",
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "View project",
                cancelButtonText: "Stay here",
                icon: "error",
            }).then((result) => {
                if (result.isConfirmed) {
                    markJobAsViewed(jobId, projectUrl, false);
                } else if (result.isDismissed) {
                    markJobAsViewed(jobId, projectUrl, true);
                }
            });
            model.alertDisplayed = true;
        } else if (
            result.status === "error" &&
            result.viewed === true &&
            model.alertDisplayed
        ) {
            Swal.close();
        }
        if (
            result.status === "in_progress" ||
            (result.status === "new" && model.statusBarDisplayed === false)
        ) {
            state = displayJobStatus(state, result);
        } else if (
            result.status === "complete" ||
            result.status === "error"
        ) {
            hideJobStatus(state);
        }
        await sleep(5000);
    }
}

function manageJobs() {
    console.log("starting to manage jobs")
    let navbarJobStatus = document.getElementById("navbar-job-status");
    /**
     * navbarJobStatus will only exist if the user is authenticated.
     * If the user isn't authenticated, then there will be no jobs to manage,
     * so we just exit early. 
    **/
    if (!navbarJobStatus) {
        console.log("No navbar job status???")
        return
    }
    let projectLinkEl = navbarJobStatus.firstElementChild;
    let statusBarDisplayed = elementIsDisplayed(navbarJobStatus);
    let project_url = null;
    let project_name = null;
    let jobStatusHeader = document.getElementById("job-status-header");
    let projectLink = document.getElementById("job-project-link");
    let cancelButton = document.getElementById("job-cancel-btn");

    let state = {
        navbarJobStatus: navbarJobStatus,
        projectLinkEl: projectLinkEl,
        project_url: project_url,
        project_name: project_name,
        jobStatusHeader: jobStatusHeader,
        projectLink: projectLink,
        cancelButton: cancelButton,
        apiUrl: "/api/v1/jobs/",
    };
    model.statusBarDisplayed = statusBarDisplayed;
    console.log("should be ready to check job status")
    checkJobStatus(state);
}

export { manageJobs, cancelJob, startJob, get, apiUrl, sleep };
