"use strict";

import "../scss/black-dashboard.scss";

import { CountUp } from "countup.js";
import * as Plotly from "plotly.js-dist";
import noUiSlider from "nouislider";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import Swal from "sweetalert2";

import { manageJobs, cancelJob, startJob, apiUrl, get, sleep} from "./jobs";

// Set up Sentry
try {
    let sentryDsn = JSON.parse(document.getElementById("sentry-dsn").textContent);
    let sentryRelease = JSON.parse(document.getElementById("sentry-release").textContent);
    let sentryEnv = JSON.parse(document.getElementById("sentry-env").textContent);
    Sentry.init({
        dsn: sentryDsn,
        environment: sentryEnv,
        integrations: [new BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        release: sentryRelease,
        tracesSampleRate: 0,
      });
} catch {
    console.log("Unable to setup Sentry.");
}

let state = {
    maps: {}
};

L.Map.addInitHook(function () {
    state.maps[this._container.attributes.id.value] = this;
});


interface State {
    [x: string]: any;
}

// Utils //
///////////

function debounce(callback, delay: number) {
    let timeout;
    return function () {
        clearTimeout(timeout);
        timeout = setTimeout(callback, delay);
    };
}

function triggerEvent(el, type) {
    var e = document.createEvent("HTMLEvents");
    e.initEvent(type, false, true);
    el.dispatchEvent(e);
}

function getPlotDiv(plotId) {
    return document.getElementById(plotId);
}

function getPlotData(plotId) {
    return JSON.parse(document.getElementById(`${plotId}-data`).textContent);
}

async function getChartData(chartId) {
    let projectId = document.getElementById(`${chartId}-plot`).dataset.project_id
    let url = `/api/v1/projects/${projectId}/charts/${chartId}`;
    let response = await fetch(url);
    let data = await response.json();
    return data;
}

function renderPlot(state) {
    Plotly.react(state.div, state.traces, state.layout, {
        displayModeBar: false,
        displaylogo: false,
        // CHANGES
        responsive: false,
    });
}

function downloadImage(prefix, format, filename) {
    // Get the original plot
    let originalPlot = document.getElementById(`${prefix}`);
    // Create a plot div offscreen
    let clonedPlot = document.createElement("div");
    clonedPlot.style.position = "absolute";
    clonedPlot.style.left = "-5000px";
    document.body.appendChild(clonedPlot);
    // @ts-ignore
    Plotly.newPlot(clonedPlot, originalPlot.data, originalPlot.layout);
    // Update the cloned plot to remove the range slider and reposition the legend    
    let params = {
        paper_bgcolor: "#27293d",
        plot_bgcolor: "#27293d",
        font: {
            color: "#FFF",
            family: "Poppins, sans-serif",
        },
    }
    params["margin"] = {
        t: 120,
    } 
    let originalLegend = originalPlot.layout.legend;
    if (originalLegend) {
        params.legend = originalLegend;
        params.legend.y = -0.3
    }
    let originalXAxis = originalPlot.layout.xaxis;
    if (originalXAxis) {
        originalXAxis.rangeslider = null;
        params.xaxis = originalXAxis;
        params.xaxis.title["font"] = {size: 20};
    }
    let originalYAxis2 = originalPlot.layout.yaxis2;
    if (originalYAxis2) {
        params.yaxis2 = originalYAxis2;
        params.yaxis2.title["font"] = {size: 20};
    }
    let originalYAxis = originalPlot.layout.yaxis;
    if (originalYAxis) {
        params.yaxis = originalYAxis;
        params.yaxis.title["font"] = {size: 20};
        params.yaxis["gridcolor"] = "#FFF";
        params.yaxis["gridwidth"] = 2;
        params.yaxis["zeroline"] = true;
        params.yaxis["zerolinewidth"] = 4;
    }
    let plotTitle = document.getElementById(`${prefix}-header`);
    if (plotTitle) {
        plotTitle = plotTitle.firstChild.textContent;
    } else {
        plotTitle = document.getElementById(prefix).previousElementSibling.firstChild.textContent;
    }
    if (!plotTitle) {
        plotTitle = document.getElementById(`${prefix}-header`).firstChild.textContent
    }
    params["title"] = {
        text: plotTitle,
        font: {
            size: 30,
        }
    }

    Plotly.update(
        clonedPlot,
        {},
        params,
    );
    // Create and download the image
    Plotly.downloadImage(clonedPlot, {
        format: format,
        width: 1000,
        height: 700,
        filename: filename,
    });
}

function enablePlotDownloads(state) {
    // Add download functionality //
    ////////////////////////////////
    ["jpeg", "png"].forEach((format) => {
        try {
            document.getElementById(
                `${state.prefix}-${format}-download-link`
            ).onclick = function (e) {
                e.preventDefault();
                let filename = state.prefix;
                downloadImage(state.prefix, format, filename);
            };
        } catch {
            console.log(`No ${state.prefix} ${format} download link available`);
        }
    })
}

function setRangeSelectorSliderWidth(state: State) {
    let sliderWidth: number;
    let maxWidth = (state.forReport) ? 1000 : state.containerWidth - 10;
    if (state.timeRange === null) {
        sliderWidth = maxWidth - 12;
    } else {
        sliderWidth = maxWidth * (state.timeRange / state.days);
    }
    if (sliderWidth < 2) {
        // Make sure the slider is always visible; if it's too small,
        // the user cannot interact with it.
        sliderWidth = 2
    }
    state.slider.style.width = `${sliderWidth}px`;
}

function setRangeSelectorSliderPosition(state: State) {
    let w = (state.forReport) ? 1000 : state.containerWidth - 10;
    let perc = state.startLeft / state.slices;
    let leftPos = w * perc;
    if (leftPos < 8) {
        leftPos = 13;
    }
    let correctionFactor = (state.forReport) ? 4 : 8;
    state.slider.style.left = leftPos - correctionFactor + "px";
}

function renderRangeSelectorSliderImage(state: State) {
    Plotly.toImage(
        // @ts-ignore
        { data: state.traces, layout: state.imageLayout },
        // Plotly's docs and type definitions state that the first argument to toImage should be a graph div,
        // but one can also just supply the data and layout. TypesScript doesn't like that, though.
        { height: 120, width: (state.forReport) ? 1000 : state.containerWidth - 10 }
    ).then(function (url) {
        state.selectorImageElement.setAttribute("src", url);
    });
}

function relayoutPlotOnRangeSelect(state) {
    Plotly.relayout(state.div, {
        "xaxis.range": [
            state.plotData.x[state.selectorBox.left],
            state.plotData.x[state.selectorBox.right],
        ],
    });
}

function setUpRangeSelector(state) {
    var slider, w, h;
    var clicked = 0;
    /* Get the width and height of the img element */
    w = (state.forReport) ? 1000 : state.containerWidth - 10;
    h = 120;
    /* Create slider: */
    if (state.slider) {
        state.slider.remove();
    }
    renderRangeSelectorSliderImage(state);
    state.slider = document.createElement("DIV");
    slider = state.slider;
    slider.setAttribute("class", "img-comp-slider");
    slider.setAttribute("id", `${state.id}-selector-slider`);
    let sliderMarker = document.createElement("DIV");
    sliderMarker.setAttribute("class", "slider-marker");
    slider.appendChild(sliderMarker);
    /* Insert slider */
    state.selectorImageElement.parentElement.insertBefore(
        state.slider,
        state.selectorImageElement
    );
    state.slider.style.height = `${h}px`;
    slider.style.top = "0px";
    setRangeSelectorSliderWidth(state);
    setRangeSelectorSliderPosition(state);
    /* Execute a function when the mouse button is pressed: */
    slider.addEventListener("mousedown", slideReady);
    /* And another function when the mouse button is released: */
    window.addEventListener("mouseup", slideFinish);
    /* Or touched (for touch screens: */
    slider.addEventListener("touchstart", slideReady);
    /* And released (for touch screens: */
    window.addEventListener("touchend", slideFinish);
    function slideReady(e) {
        /* Prevent any other actions that may occur when moving over the image: */
        e.preventDefault();
        /* The slider is now clicked and ready to move: */
        clicked = 1;
        /* Execute a function when the slider is moved: */
        window.addEventListener("mousemove", slideMove);
        window.addEventListener("touchmove", slideMove);
    }
    function slideFinish() {
        if (clicked !== 1) {
            return false;
        }
        clicked = 0;
        relayoutPlotOnRangeSelect(state);
    }
    function slideMove(e) {
        var pos;
        /* If the slider is no longer clicked, exit this function: */
        if (clicked == 0) return false;
        /* Get the cursor's x position: */
        pos = getCursorPos(e);
        /* Prevent the slider from being positioned outside the image: */
        if (pos < 0) pos = 0;
        if (pos > w) pos = w;
        /* Execute a function that will resize the overlay image according to the cursor: */
        slide(pos);
    }
    function getCursorPos(e) {
        var a,
            x = 0;
        e = e || window.event;
        /* Get the x positions of the image: */
        a = state.selectorImageElement.getBoundingClientRect();
        /* Calculate the cursor's x coordinate, relative to the image: */
        x = e.pageX - a.left;
        /* Consider any page scrolling: */
        x = x - window.pageXOffset;
        return x;
    }
    function slide(x) {
        var halfWidth = slider.offsetWidth / 2;
        var imgWidth = state.selectorImageElement.offsetWidth - 10;
        if (x <= halfWidth) {
            state.selectorBox.left = 0;
            state.selectorBox.right = state.timeRange * 24 - 1;
            slider.style.left = "5px";
        } else if (x + halfWidth >= state.selectorImageElement.offsetWidth) {
            var startLeft = state.slices - state.timeRange * 24;
            if (state.timeRange >= 1) {
                startLeft = startLeft - (startLeft % 24);
            }
            state.selectorBox.right = state.slices - 1;
            state.selectorBox.left = startLeft;
            slider.style.left = `${
                state.selectorImageElement.offsetWidth - slider.offsetWidth - 5
            }px`;
        } else {
            var steps = imgWidth / state.slices;
            var hWidth = (halfWidth / imgWidth) * steps;
            var xPos = (x / imgWidth) * state.slices;
            var left = Math.floor(xPos - hWidth);
            var left = x - halfWidth;
            var steps = state.slices / imgWidth;
            var leftIndex = Math.floor(left * steps);
            if (state.timeRange >= 1) {
                // Check if it's the start of a day
                if (leftIndex <= 24) {
                    leftIndex = 24;
                } else {
                    leftIndex = leftIndex - (leftIndex % 24);
                }
            }
            state.selectorBox.left = leftIndex;
            let maxRight = state.slices - 1;
            let rightIndex = leftIndex + state.timeRange * 24 - 1;

            if (state.resolution === "hourly" && !state.isMultiYear) {
                if (state.selectedRange === "6-hours") {
                    rightIndex = leftIndex + state.timeRange * 6;
                }
                rightIndex = leftIndex + state.timeRange * 24;
            }

            if (rightIndex >= maxRight) {
                rightIndex = maxRight;
            }
            state.selectorBox.right = rightIndex;
            slider.style.left = x - slider.offsetWidth / 2 + "px";
        }
        state.startLeft = state.selectorBox.left;
        state.startRight = state.selectorBox.right;
    }
    let radios = document.querySelectorAll(
        `input[type=radio][name="${state.prefix}-timerange"]`
    );
    let correctionFactor = state.resolution === "fifteen_minutes" ? 4 : 1;
    let oneDay = 1 * correctionFactor;
    let sevenDays = 7 * correctionFactor;
    let oneMonth = 30 * correctionFactor;
    let sixMonths = (30 * 6) * correctionFactor;
    let oneYear = 365 * correctionFactor;
    let sixHours = (1 / 4) * 6 * correctionFactor;
    if (state.isMultiYear || state.resolution === "fifteen_minutes") {
        sixHours = sixHours / 6;
    } else {
        sixHours = sixHours / 6
    }
    for (let radio of radios) {
        radio.onchange = function () {
            state.selectedValue = radio.value;
            switch (radio.value) {
                case "1-day":
                    state.timeRange = oneDay;
                    break;
                case "7-days":
                    state.timeRange = sevenDays;
                    break;
                case "1-month":
                    state.timeRange = oneMonth;
                    break;
                case "6-months":
                    state.timeRange = sixMonths;
                    break;
                case "1-year":
                    state.timeRange = oneYear;
                    break;
                case "6-hours":
                    state.timeRange = sixHours;
                    break;
                case "all-years":
                    state.timeRange = null;
                    break;
            }
            if (state.timeRange === null || (state.timeRange === oneYear && !state.isMultiYear)) {
                state.startLeft = 0;
                state.endRight = state.slices - 1;
            } else {

                if (state.slices > 8760 && !state.isMultiYear) {
                    state.endRight = state.startLeft + state.timeRange * 24;
                } else {
                    // Optional if we want to make the selector jump to the left
                    // if (state.isMultiYear) {
                    //     state.startLeft = 0;
                    // }
                    state.endRight = state.startLeft + state.timeRange * 24;
                }
                let diff = state.endRight - (state.slices - 1);
                if (diff > 0) {
                    state.startLeft = state.startLeft - diff;
                    state.endRight = state.endRight - diff;
                }
            }
            // Pin the start to the start of a day if the time range is not fractional
            if (state.timeRange < 1 && state.timeRange > 0) {
                state.startLeft = state.startLeft - (state.startLeft % 24);
            }
            state.layout.xaxis.range = [
                state.plotData.x[state.startLeft],
                state.plotData.x[state.endRight],
            ];
            state.selectorBox.right = state.endRight;
            state.selectorBox.left = state.startLeft;
            setRangeSelectorSliderWidth(state);
            setRangeSelectorSliderPosition(state);
            relayoutPlotOnRangeSelect(state);
        };
    }
}

function makeTraces(plotData, order, labels, colors) {
    let xValues = plotData.x;
    let traces = [];
    order.forEach((componentType) => {
        let yValues = plotData[componentType];
        if (typeof yValues !== "undefined") {
            let trace = {
                x: xValues,
                y: yValues,
                mode: "lines",
                name: labels[componentType],
                opacity: 1,
                fillcolor: colors[componentType],
                line: { color: colors[componentType] },
            }
            if (componentType === "demand") {
                trace.line.dash = "dash";
            } else {
                trace.stackgroup = "one";
            }
            traces.push(trace);
        }
    });
    return traces;
}

const seriesNames = {
    wind: "Wind: source",
    solar: "Solar: source",
    primary_reserve_pos: "Primary reserve pos",
    primary_reserve_neg: "Primary reserve neg",
    secondary_reserve_pos: "Secondary reserve pos",
    secondary_reserve_neg: "Secondary reserve neg",
    tertiary_reserve_pos: "Tertiary reserve pos",
    tertiary_reserve_neg: "Tertiary reserve neg",
    primary_reserve_pos_neg_price: "Primary reserve pos/neg price",
    primary_reserve_pos_price: "Primary reserve pos price",
    primary_reserve_neg_price: "Primary reserve neg price",
    secondary_reserve_pos_price: "Secondary reserve pos price",
    secondary_reserve_neg_price: "Secondary reserve neg price",
    tertiary_reserve_pos_price: "Tertiary reserve pos price",
    tertiary_reserve_neg_price: "Tertiary reserve neg price",
    electricity_prices_buy: "Electricity prices buy",
    electricity_prices_sell: "Electricity prices sell",
    intraday_prices_buy: "Intraday prices buy",
    intraday_prices_sell: "Intraday prices sell",
    real_time_prices_buy: "Real time prices buy",
    real_time_prices_sell: "Real time prices sell",
    gas_prices: "Gas prices",
    diesel_prices: "Diesel prices",
    coal_prices: "Coal prices",
    additional_prices: "Additional prices",
    max_grid_inflow: "Max grid inflow",
    max_grid_outflow: "Max grid outflow",
    ambient_temperatures: "Ambient temperatures",
    demand: "Demand",
    "solar generation kW": "Solar generation: source [kW]",
    "wind generation kW": "Wind generation: source [kW]",
    allow_market_charge: "Allow market charge",
    consider_max_load_in_grid_charges: "Consider max load in grid charges",
    hydrogen_consumption: "Hydrogen consumption",
    hydrogen_price: "Hydrogen price",
    "day-ahead market revenues": "Day-ahead",
    "intraday market revenues": "Intraday",
    "real-time market revenues": "Real-time",
    "final settlement (ID) market revenues": "Final settlement (ID)",
    "reserve (energy) market revenues": "Reserve (energy)",
    "reserve (full revenue capacity) market revenues": "Reserve (full revenue capacity)",
    "total market revenues": "Total",
}

function makeScatterPlotTraces(plotData) {
    let xValues = plotData.x;
    let traces = [];
    plotData.y.forEach((obj) => {
        if (plotData.for_report) {
            obj.visible = true;
        }
        let trace = {
            x: xValues,
            y: obj.values,
            mode: "lines",
            name: seriesNames[obj.name] ? seriesNames[obj.name] : obj.name,
            opacity: 0.9,
            hovertemplate: "%{y:.2f} [kW]",
            visible: obj.visible
        }
        if (obj.line) {
            trace.line = obj.line;
        }
        if (obj.name === "available stored energy") {
            trace.hovertemplate = "%{y:.2f} [kWh]";
        }
        if (obj.name === "ambient_temperatures") {
            trace.hovertemplate = "%{y:.2f} °C";
        }
        if (obj.name.endsWith("number of plants operating") || obj.name.endsWith("number of starts") || obj.name.endsWith("allow_market_charge") || obj.name.endsWith("consider_max_load_in_grid_charges")) {
            trace.hovertemplate = "%{y:.0f}";
        }
        if (obj.name.endsWith(": state of charge") || obj.name.endsWith("average efficiency per plant")) {
            trace.yaxis = "y2";
            trace.hovertemplate = "%{y:.2f} [%]";
        }
        if (obj.name.endsWith("price") || obj.name.endsWith("prices") || obj.name.endsWith("prices") || obj.name.endsWith("prices_buy") || obj.name.endsWith("prices_sell")) {
            trace.hovertemplate = "%{y:.2f} " + `[${plotData.currency}/kWh]`;
        }
        if (obj.name.endsWith("revenues")) {
            trace.hovertemplate = "%{y:,.2f} " + `[${plotData.currency}]`;
        }
        traces.push(trace);
    });
    return traces;
}

// Layouts //
/////////////

function makeTimeSeriesLayout(yAxisLabel, convertPercent=true, xAxisLabel="Intervals", yAxisLabels=[]) {
    let hoverformat;
    let tickvals;
    let range;
    let yAxis1Label;
    if (yAxisLabels) {
        yAxis1Label = yAxisLabels[0]
    } else {
        yAxis1Label = yAxisLabel
    }
    let yAxis2Label;
    if (yAxisLabels.length > 1) {
        yAxis2Label = yAxisLabels[1]
    } else {
        yAxis2Label = "Percent [%]"
    }
    if (!convertPercent) {
        hoverformat = ",.4r";
        tickvals = null;
        range = [0, 100];
    } else {
        hoverformat = ",.2%"
        tickvals = [0, 0.2, 0.4, 0.6, 0.8, 1];
        range = [0, 1];
    }

    return {
        xaxis: {
            automargin: true,
            showgrid: false,
            fixedrange: true,
            hoverformat: "%d-%m-%Y %H:%M",
            title: {
                text: xAxisLabel,
                standoff: 10,
            },
        },
        yaxis: {
            tickformat: ",",
            automargin: true,
            title: {
                text: yAxis1Label,
                standoff: 15,
            },
            fixedrange: true,
            hoverformat: ",.2f",
        },
        yaxis2: {
            automargin: true,
            title: {
                text: yAxis2Label,
                standoff: -15,
            },
            fixedrange: true,
            range: [0, 100],
            overlaying: "y",
            side: "right",
            tickmode: "array",
            tickvals: tickvals,
            ticktext: ["0", "20", "40", "60", "80", "100"],
            hoverformat: hoverformat,
            showgrid: false,
        },
        showlegend: true,
        legend: {
            orientation: "h",
            y: -0.2,
        },
        paper_bgcolor: "transparent",
        plot_bgcolor: "transparent",
        font: {
            color: "#FFF",
            family: "Poppins, sans-serif",
        },
        margin: {
            r: 20,
            b: 50,
            t: 20,
            pad: 4,
        },
        height: 600,
        hovermode: "x unified",
        hoverlabel: { bgcolor: "#6c757d", namelength: -1 },
    };
}

function makeStackedAreaLayout(yAxisLabel) {
    let layout = makeTimeSeriesLayout(yAxisLabel);
    // @ts-ignore
    layout.legend.y = -0.12;
    return layout;
}

function makeScatterPlotLayout(yAxisLabel, convertPercent=true, xAxisLabel) {
    let layout = makeTimeSeriesLayout(yAxisLabel, convertPercent, xAxisLabel);
    return layout;
}

function makeBasicPlotLayout(plotData) {
    return {
        title: null,
        xaxis: {
            automargin: true,
            title: plotData.x_label
        },
        yaxis: {
            title: plotData.y_label,
            tickformat: "s",
            hoverformat: ",",
            range: [] },
        showlegend: false,
        paper_bgcolor: "transparent",
        plot_bgcolor: "transparent",
        font: { color: "rgba(255,255,255, .8)", family: "Poppins" },
        margin: {
            r: 20,
            b: 50,
            t: 20,
            pad: 4,
        },
        hovermode: "closest",
        height: 600,
    };
}

function makeSelectorPlotLayout(mainPlotLayout) {
    let selectorPlotLayout = JSON.parse(JSON.stringify(mainPlotLayout));
    selectorPlotLayout.showlegend = false;
    selectorPlotLayout.xaxis.showgrid = false;
    selectorPlotLayout.yaxis.showgrid = false;
    selectorPlotLayout.title = null;
    selectorPlotLayout.xaxis.title = null;
    selectorPlotLayout.yaxis.title = null;
    selectorPlotLayout.xaxis.showticklabels = false;
    selectorPlotLayout.yaxis.showticklabels = false;
    selectorPlotLayout.margin = {
        r: 5,
        b: 10,
        t: 10,
        l: 5,
        pad: 4,
    };
    if (selectorPlotLayout.yaxis2) {
        selectorPlotLayout.yaxis2.title = null;
        selectorPlotLayout.yaxis2.showticklabels = false;
    }
    return selectorPlotLayout;
}

// Traces //
////////////

function makeBarPlotTraces(plotData) {
    let xValues = plotData.x;
    let traces = [];
    plotData.y.forEach((obj) => {
        traces.push({
            x: xValues,
            y: obj.values,
            type: "bar",
            name: obj.name,
            opacity: 0.9,
        });
    });
    return traces;
}

// Plots //
///////////

function sum(array) {
    if (array.length) {
        return array.reduce((a, b) => {
            return a + b;
          });
    }
    return 0
}

async function getPlotState(plotName) {
    // Get the initial state
    console.log(`getting state for ${plotName}`);
    let plotId = `${plotName}-plot`;
    var div = document.getElementById(plotId);
    let selectorImageElement = document.getElementById(`${plotId}-selector-image`);
    let containerWidth = document.getElementById(`${plotId}-container`).clientWidth;
    if (containerWidth === 0) {
        containerWidth = document.getElementById("plot-panel").clientWidth;
    }
    let plotData
    if (
        [
            "efficiency",
            "installed-capacity",
            "electricity-generation",
            "additional-timeseries",
            "heat1-generation",
            "heat2-generation",
            "stacked-annual-generation",
            "initial-investment",
            "dimensions",
            "costs",
            "production",
            "annual-cash-flows-per-component",
            "inputs-timeseries",
            "load-profile",
            "revenues",
            "peak-demand-vs-installed-capacity",
        ].includes(plotName)) {
        try {
            plotData = await getChartData(plotName)
        } catch (err) {
            alert("Error getting chart data--please reload the page")
            Sentry.captureException(err)
        }
    } else {
        plotData = getPlotData(plotId);
    }
    plotData.for_report = div.dataset.for_report;
    let slices;
    let days;
    let timeRange;
    let startLeft;
    let endRight;        
    if (plotData.x) {
        slices = plotData.x.length;
        days = slices / 24;
        timeRange = 7;
        if (plotData.resolution === "fifteen_minutes") {
            timeRange = timeRange * 4;
        }
        startLeft = slices - timeRange * 24;     
        startLeft = startLeft - (startLeft % 24);
        endRight = slices - 1;        
    } else {
        slices = null;
        days = null;
        timeRange = null;
        startLeft = null;
        endRight = null;
    }
    let imageLayout = null;
    let includeSelector = false;
    let timeRangeSelect = document.getElementById(
        `${plotId}-time-range-select`
    )
    let layout;
    let traces;
    let plotPanel = document.getElementById("plot-panel");
    let fontColor = (plotData.for_report) ? "#212529" : "#FFF";
    switch (plotName) {
        case "stacked-annual-generation":
            let colors = {
                battery: "#414141", /// Black
                hydro: "#A6E2FF", // Light blue
                wind: "#5A67FF", // Blue
                solar: "#FFE200", // Yellow
                genset: "#788075", // Gray
                market: "#c7dada", // Light gray
            };
            let order = [
                "battery", // Battery
                "market", // Market
                "hydro", // Hydro
                "wind", // Wind
                "solar", // Solar
                "genset", // Diesel and gas gensets
                "demand", // Demand
            ];
            let labels = {
                battery: "Battery", // Battery
                hydro: "Hydro", // Hydro
                wind: "Wind", // Wind
                solar: "Solar", // Solar
                genset: "Genset", // Diesel and gas gensets
                market: "Market", // Market
                demand: "Demand", // Demand
            };
            layout = makeStackedAreaLayout("Electricity generation [kW]");
            if (plotData.for_report) {
                layout.height = 525;
                layout.width = 1000;
            }
            imageLayout = makeSelectorPlotLayout(layout);
            layout.xaxis.range = [plotData.x[startLeft], plotData.x[endRight]];
            traces = makeTraces(plotData, order, labels, colors);
            includeSelector = (plotData.for_report) ? true : true;
            break;
        case "initial-investment":
            let plotType = "pie";
            layout = {
                paper_bgcolor: "transparent",
                plot_bgcolor: "transparent",
                font: {
                    color: "#FFF",
                    family: "Poppins, sans-serif",
                },
                margin: {
                    r: 20,
                    l: 20,
                    b: 50,
                    t: 70,
                    pad: 4,
                },
                height: 550,
            };
            if (plotData.for_report) {
                layout.height = 450;
            } else {
                layout.height = 400;
                layout.margin.b = 120;
                layout.margin.r = 80;
                layout.margin.l = 80;
                layout.showlegend = false;
            }
            traces = [
                {
                    values: plotData.values,
                    labels: plotData.labels,
                    type: plotType,
                    insidetextorientation: "radial",
                    hovertemplate:
                        "%{label}<br>%{value} %{text}<br>%{percent}<extra></extra>",
                    texttemplate: "%{label}<br>%{value} %{text}",
                    text: plotData.currency,
                    textposition: "outside",
                    automargin: true,
                },
            ];
            break;
        case "efficiency":
            layout = {
                paper_bgcolor: "transparent",
                plot_bgcolor: "transparent",
                font: {
                    color: "#FFF",
                    family: "Poppins, sans-serif",
                },
                margin: {
                    r: 20,
                    l: 20,
                    b: 70,
                    t: 20,
                    pad: 30,
                },
                height: 350,
            };
            let x_positions = plotData.x;
            let y_positions = plotData.y;
            function makeLink(rawLink) {
                if (plotData.for_report) {
                    rawLink.color = "#abb3b2";
                }
                return rawLink;
            }
            traces = [
                {
                    type: "sankey",
                    arrangement: "snap",
                    domain: {
                        x: [0, 1],
                        y: [0, 1],
                    },
                    orientation: "h",
                    valueformat: ".0f",
                    valuesuffix: "kWh",
                    node: {
                        pad: 15,
                        thickness: 15,
                        line: {
                            color: "black",
                            width: 0.5,
                        },
                        label: plotData.label,
                        x: x_positions,
                        y: y_positions,
                        color: plotData.color,
                    },
                    link: makeLink(plotData.link),
                },
            ];
            if (plotData.for_report) {
                layout.height = 525;
                layout.width = 1000
            }
            break;
        case "heat1-generation":
            layout = makeScatterPlotLayout("Heat1 generation [kW]", false);
            if (plotData.for_report) {
                layout.height = 525;
                layout.width = 1000
            }
            imageLayout = makeSelectorPlotLayout(layout);
            traces = makeScatterPlotTraces(plotData);
            layout.xaxis.range = [plotData.x[startLeft], plotData.x[endRight]];
            includeSelector = true;        
            break;
        case "heat2-generation":
            layout = makeScatterPlotLayout("Heat2 generation [kW]", false);
            if (plotData.for_report) {
                layout.height = 525;
                layout.width = 1000
            }
            imageLayout = makeSelectorPlotLayout(layout);
            traces = makeScatterPlotTraces(plotData);
            layout.xaxis.range = [plotData.x[startLeft], plotData.x[endRight]];
            includeSelector = true;        
            break;
        case "electricity-generation":
            layout = makeScatterPlotLayout("Electricity generation [kW]", false);
            if (plotData.for_report) {
                layout.height = 525;
                layout.width = 1000
            }
            imageLayout = makeSelectorPlotLayout(layout);
            traces = makeScatterPlotTraces(plotData);
            layout.xaxis.range = [plotData.x[startLeft], plotData.x[endRight]];
            includeSelector = true;        
            break;
        case "revenues":
            layout = makeScatterPlotLayout("Market Revenues [plotData.currency]", false);
            if (plotData.for_report) {
                layout.height = 525;
                layout.width = 1000
            }
            layout.yaxis.title = {
                text: `[${plotData.currency}]`,
            }
            let xaxisTitle: string;
            if (plotData.resolution == "hour") {
                xaxisTitle = "Hourly intervals";
            } else {
                xaxisTitle = "!5-minute intervals"
            }
            layout.xaxis.title = {
                text: xaxisTitle,
            }
            imageLayout = makeSelectorPlotLayout(layout);
            traces = makeScatterPlotTraces(plotData);
            layout.xaxis.range = [plotData.x[startLeft], plotData.x[endRight]];
            includeSelector = true;
            break;
        case "additional-timeseries":
            layout = makeScatterPlotLayout("Electricity generation [kW]", false, plotData.x_label);
            if (plotData.for_report) {
                layout.height = 525;
                layout.width = 1000
            }
            imageLayout = makeSelectorPlotLayout(layout);
            traces = makeScatterPlotTraces(plotData);
            layout.xaxis.range = [plotData.x[startLeft], plotData.x[endRight]];
            includeSelector = true;
            break;
        case "inputs-timeseries":
            layout = makeScatterPlotLayout("Electricity generation [kW]", false, plotData.x_label);
            if (plotData.for_report) {
                layout.height = 525;
                layout.width = 1000
            }
            if (plotPanel) {
                layout.width = plotPanel.clientWidth - 10
            }
        
            imageLayout = makeSelectorPlotLayout(layout);
            traces = makeScatterPlotTraces(plotData);
            layout.xaxis.range = [plotData.x[startLeft], plotData.x[endRight]];
            includeSelector = true;
            break;
        case "load-profile":
            layout = makeBasicPlotLayout(plotData);
            if (plotPanel) {
                layout.width = plotPanel.clientWidth - 10
            }
            imageLayout = makeSelectorPlotLayout(layout);
            traces = makeScatterPlotTraces(plotData);
            layout.title = null;
            layout.xaxis.range = [plotData.x[startLeft], plotData.x[endRight]];
            includeSelector = true;
            break;
        case "component-output":
            layout = makeBasicPlotLayout(plotData);
            traces = makeScatterPlotTraces(plotData);
            break;
        case "installed-capacity":
            layout = makeBasicPlotLayout(plotData);
            if (plotData.for_report) {
                layout.height = 530;
                layout.width = 1000
                layout.margin.b = 120;
                layout.margin.r = 80;
            } else {
                layout.height = 350;
                layout.margin.b = 120;
                layout.margin.r = 80;
                layout.margin.l = 80;
            }
            layout.hovermode = "closest";
            layout.yaxis.range = plotData.y_range;
            if (plotData.y2_label) {
                layout.yaxis2 = {
                    tickformat: ",",
                    title: plotData.y2_label,
                    overlaying: "y",
                    side: "right",
                    range: plotData.y_range,
                    tickformat: ",",
                };
                if (plotData.for_report) {
                    layout.showlegend = true;
                    layout.legend = {
                        x: 1,
                        y: 1,
                        xanchor: "right",
                        text: "Legend"
                    };
                    layout.annotations = [
                        {
                            y: 1.05,
                            x: 1,
                            align: "right",
                            valign: "top",
                            yref: "paper",
                            xref: "paper",
                            text: "Legend",
                            showarrow: false,
                            xanchor: "right",
                            yanchor: "top"
                        }
                    ]
                } else {
                    layout.showlegend = false;
                    layout.legend = null;
                }


            }
            traces = plotData.traces;
            break;
        case "annual-cash-flows-per-component":
            layout = makeBasicPlotLayout(plotData);
            layout.margin.b = 120;
            layout.margin.r = 80;
            layout.hovermode = "x unified";
            layout.hoverlabel = { bgcolor: "#6c757d" }
            layout.yaxis.range = plotData.y_range;
            layout.yaxis.hoverformat = ",.0f";
            layout.barmode = "relative";
            layout.showlegend = true;
            layout.legend = {
                orientation: "h",
            }
            if (plotData.for_report) {
                layout.legend.y = -0.2
            } else {
                layout.legend.y = -0.5
            }
 
            if (plotData) 
            if (plotData.y2_label) {
                layout.yaxis2 = {
                    tickformat: ",",
                    title: plotData.y2_label,
                    overlaying: "y",
                    side: "right",
                    range: plotData.y_range,
                };
                layout.showlegend = true;
                layout.legend = {
                    x: 1,
                    y: 1,
                    xanchor: "right",
                    text: "Legend"
                };
                layout.annotations = [
                    {
                        y: 1.05,
                        x: 1,
                        align: "right",
                        valign: "top",
                        yref: "paper",
                        xref: "paper",
                        text: "Legend",
                        showarrow: false,
                        xanchor: "right",
                        yanchor: "top"
                    }
                ]
            }
            traces = plotData.traces;
            if (plotData.for_report) {
                layout.height = 525;
                layout.width = 1000
            } else {
                layout.height = 350;
                layout.margin.b = 120;
                layout.margin.r = 80;
                layout.margin.l = 80;
            }
            break;    
        case "peak-demand-vs-installed-capacity":
            layout = makeBasicPlotLayout(plotData);
            layout.xaxis.title = null;
            layout.hovermode = null;
            layout.margin.b = 120;
            layout.margin.r = 80;
            layout.showlegend = true;
            layout.legend = {
                x: 0,
                y: -.4,
            };
            let componentSchematicColumn = document.getElementById("component-schematic-column")
            if (componentSchematicColumn) {
                let columnHeight = componentSchematicColumn.offsetHeight - 135
                if (columnHeight < 350) {
                    columnHeight = 350
                }
                layout.height = columnHeight
                if (columnHeight < 350) {
                    layout.legend = {
                        x: 0,
                        y: -.9,
                    };
                }
            }
            layout.hovermode = "closest";
            if (plotData.y2_label) {
                layout.yaxis2 = {
                    tickformat: "s",
                    hoverformat: ",",
                    title: plotData.y2_label,
                    overlaying: "y",
                    side: "right",
                };
            }
            layout.barmode = "stack";
            let xValues = ["Peak demand", "Producers", "Market", "Storage"]
            let kWValues = []
            let kWhValues = []
            let peakDemandValue = plotData.peak_demand_value;
            traces = [
                {
                    x: xValues,
                    y: [peakDemandValue, null, null, null],
                    name: "Peak demand",
                    type: "bar",
                    hovertemplate: "<b>%{fullData.name}</b>:<br>%{y:,.0f} %{yaxis.title.text}<br><extra></extra>",
                    opacity: 0.9,
                },
            ]
            let components;
            let onlySelected;
            components = Array.from(document.getElementsByClassName("asset-form-row"));
            if (components.length) {
                onlySelected = true;
            } else {
                components = Array.from(document.getElementsByClassName("min-max-row"));
                onlySelected = false;
            }
            for (let component of components) {
                let selected;
                if (onlySelected) {
                    let selectorEl = document.getElementById(`selected-${component.dataset.componentId}`)
                    selected = selectorEl.checked;
                } else {
                    selected = true;
                }
                if (selected) {
                    let compy = {
                        name: component.dataset.componentName,
                        type: component.dataset.componentType,
                        unitCapacity: component.dataset.componentUnitCapacity,
                        id: onlySelected ? component.dataset.componentId : component.dataset.asset_id,
                    }
                    let maxElId;
                    maxElId = onlySelected ? `max-${compy.id}` : `asset-max-${compy.id}`
                    let numUnits;
                    if (onlySelected) {
                        numUnits = parseFloat(document.getElementById(maxElId).value) || 0;
                    } else {
                        numUnits = parseFloat(document.getElementsByName(maxElId)[0].value) || 0;
                    }
                    let unitCapacity;
                    // Calculate the unit capacity based on the component type and its general settings
                    switch (compy.type) {
                        case "basicstorage":
                            let capacityEl = document.getElementById(`capacity-${compy.id}`);
                            if (capacityEl) {
                                unitCapacity = parseFloat(capacityEl.value) || 0;
                            } else {
                                unitCapacity = compy.unitCapacity;
                            }
                            break;
                        default:
                            let maxEl = document.getElementById(`_max-${compy.id}`);
                            let maxMechEl = document.getElementById(`_max_mech-${compy.id}`);
                            if (maxMechEl) {
                                unitCapacity = parseFloat(maxMechEl.value) || 0;
                            } else if (maxEl) {
                                unitCapacity = parseFloat(maxEl.value) || 0;
                            } else {
                                unitCapacity = compy.unitCapacity;
                            }
                    }
                    compy.maxCapacity = unitCapacity * numUnits
                    if (compy.type === "basicstorage") {
                        kWhValues.push(compy.maxCapacity)
                        traces.push(
                            {
                                x: xValues,
                                y: [null, null, null, compy.maxCapacity],
                                name: compy.name,
                                type: "bar",
                                hovertemplate: "<b>%{fullData.name}</b>:<br>%{y:,.0f} %{yaxis.title.text}<br><extra></extra>",
                                yaxis: "y2",
                                opacity: 0.9,
                            }
                        )
                    } else if (compy.type === "market") {
                        kWhValues.push(compy.maxCapacity)
                        traces.push(
                            {
                                x: xValues,
                                y: [null, null, compy.maxCapacity,  null],
                                name: compy.name,
                                type: "bar",
                                hovertemplate: "<b>%{fullData.name}</b>:<br>%{y:,.0f} %{yaxis.title.text}<br><extra></extra>",
                                opacity: 0.9,
                            }
                        )
                    } else {
                        kWValues.push(compy.maxCapacity)
                        traces.push(
                            {
                                x: xValues,
                                y: [null, compy.maxCapacity, null, null],
                                name: compy.name,
                                type: "bar",
                                hovertemplate: "<b>%{fullData.name}</b>:<br>%{y:,.0f} %{yaxis.title.text}<br><extra></extra>",
                                opacity: 0.9,
                            }
                        )
                    }
                }
            }
            let y_max = Math.max(
                peakDemandValue,
                sum(kWValues),
                sum(kWhValues)
            )
            layout.yaxis.range = [0, y_max]
            layout.yaxis2.range = [0, y_max]
            break;
        case "costs":
            layout = makeBasicPlotLayout(plotData);
            if (plotData.for_report) {
                layout.height = 500;
                layout.width = 450;
            } else {
                layout.height = 350;
                layout.margin.b = 120;
                layout.margin.r = 80;
                layout.margin.l = 80;
            }
            traces = makeBarPlotTraces(plotData);
            break;
        case "production":
            layout = makeBasicPlotLayout(plotData);
            traces = makeBarPlotTraces(plotData);
            if (plotData.for_report) {
                layout.height = 525;
                layout.width = 1000
            } else {
                layout.height = 350;
                layout.margin.b = 120;
                layout.margin.r = 80;
                layout.margin.l = 80;
            }
            break;
        default:
            layout = makeBasicPlotLayout(plotData);
            traces = makeScatterPlotTraces(plotData)
    }
    layout.font.color = fontColor;
    if (plotData.for_report) {
        if (layout.yaxis) {
            layout.yaxis.gridcolor = fontColor;
        }
        if (layout.yaxis2) {
            layout.yaxis2.gridcolor = fontColor;
        } 
    }
    return {
        div: div,
        traces: traces,
        layout: layout,
        imageLayout: imageLayout,
        prefix: plotId,
        plotData: plotData,
        selectorImageElement: selectorImageElement,
        containerWidth: containerWidth,
        selectorBox: { left: 0, right: 100 },
        slices: slices,
        days: days,
        timeRange: timeRange,
        slider: null,
        startLeft: startLeft,
        endRight: endRight,
        includeSelector: includeSelector,
        timeRangeSelect: timeRangeSelect,
        resolution: plotData.resolution,
        isMultiYear: plotData.is_multi_year,
        selectedRange: null,
        forReport: plotData.for_report,
    };    
}

function setTriggers(plotName) {

    switch (plotName) {
        case "load-profile":
            let form = document.getElementById(
                "load-profile-plot-form"
            ) as HTMLFormElement;
            let field = document.getElementById(
                "id_demand_source"
            ) as HTMLInputElement;
            let useGeneratorButton = document.getElementById("use-load-generator");
            let useInputFileButton = document.getElementById("use-input-file");
            useGeneratorButton.onclick = function (e) {
                e.preventDefault();
                field.value = "load_generator";
                form.submit();
            };   
            useInputFileButton.onclick = function (e) {
                e.preventDefault();
                field.value = "user_input_file";
                form.submit();
            };
            break;
        case "peak-demand-vs-installed-capacity":
            let components;
            let onlySelected;
            components = Array.from(document.getElementsByClassName("asset-form-row"));
            if (components.length) {
                onlySelected = true;
            } else {
                components = Array.from(document.getElementsByClassName("min-max-row"));
                onlySelected = false;
            }
            for (let component of components) {
                let selectorEl = document.getElementById(`selected-${component.dataset.componentId}`)
                if (selectorEl) {
                    selectorEl.addEventListener("change", function(event) {
                        event.preventDefault()
                        let state = getPlotState(plotName);
                        update(state)
                    })
                }
                let maxElId;
                maxElId = onlySelected ? `max-${component.dataset.componentId}` : `asset-max-${component.dataset.asset_id}`
                let maxValueEl = onlySelected ? document.getElementById(maxElId) : document.getElementsByName(maxElId)[0]
                maxValueEl.addEventListener("keyup", function(event) {
                    event.preventDefault()
                    if ((onlySelected && document.getElementById(`${event.target.id.replace("max-", "selected-")}`).checked) || !onlySelected) {
                        let state = getPlotState(plotName);
                        update(state)
                    }
                })
                switch (component.dataset.componentType) {
                    case "basicstorage":
                        console.log("trying to update basic")
                        let capacityEl = document.getElementById(`capacity-${component.dataset.componentId}`)
                        if (capacityEl) {
                            capacityEl.addEventListener("keyup", function(event) {
                                event.preventDefault()
                                if (document.getElementById(`${event.target.id.replace("capacity-", "selected-")}`).checked) {
                                    let state = getPlotState(plotName);
                                    update(state)
                                }
                            })
                        }
                        break;
                    default:
                        let maxEl = document.getElementById(`_max-${component.dataset.componentId}`);
                        let maxMechEl = document.getElementById(`_max_mech-${component.dataset.componentId}`);
                        if (maxMechEl) {
                            maxMechEl.addEventListener("keyup", function(event) {
                                event.preventDefault()
                                if (document.getElementById(`${event.target.id.replace("_max_mech-", "selected-")}`).checked) {
                                    let state = getPlotState(plotName);
                                    update(state)
                                }
                            })
                        } else if (maxEl) {
                            maxEl.addEventListener("keyup", function(event) {
                                event.preventDefault()
                                if (document.getElementById(`${event.target.id.replace("_max-", "selected-")}`).checked) {
                                    let state = getPlotState(plotName);
                                    update(state)
                                }
                            })
                        }
                }
            }
        default:
            // No action required now
    }
}

const updatePlot = (state) =>
    new Promise(function (resolve, reject) {
        renderPlot(state);
        if (state.includeSelector) {
            setUpRangeSelector(state);
        }
        resolve(state);
    });

function update(state) {
    Promise.resolve(state).then(updatePlot);
    }

async function makePlotNew(plotName) {
    let state = await getPlotState(plotName);
    if (state.includeSelector || plotName === "peak-demand-vs-installed-capacity") {
        setTriggers(plotName);
    }
    update(state);
    if (state.plotData.comment) {
        let commentEl = document.getElementById(`${state.id}-comment`);
        commentEl.innerHTML = `<span class="font-italic">Note </span>— ${state.plotData.comment}`;
        commentEl.classList.remove("d-none");
    }
    enablePlotDownloads(state);
}

function makePlots() {
    let plotNames = [
        "component-output",
        "installed-capacity",
        "costs",
        "production",
        "load-profile",
        "electricity-generation",
        "initial-investment",
        "heat1-generation",
        "heat2-generation",
        "stacked-annual-generation",
        "efficiency",
        "peak-demand-vs-installed-capacity",
        "annual-cash-flows-per-component",
        "additional-timeseries",
        "inputs-timeseries",
        "revenues",
    ]
    let plotDivs = Array.from(document.querySelectorAll("[id$='-plot']"))
    for (let plotDiv of plotDivs) {
        let plotName = plotDiv.id.replace("-plot", "");
        if (plotNames.includes(plotName)) {
            makePlotNew(plotName)
        }
    }
}

async function loadGeneratorPlot() {
    let state: State;

    async function getInitialState() {
        // Get the initial state
        console.log("getting state");
        let plotId = "load-generator-plot";
        let div = getPlotDiv(plotId);
        let scalingSettings = JSON.parse(document.getElementById("load-generator-scaling-data").textContent);
        let plotData;
        try {
            plotData = await getChartData("load-generator")
        } catch (err) {
            alert("Error getting chart data--please reload the page")
            Sentry.captureException(err)
        }
        plotData["scaling"] = scalingSettings;
        let traces = makeScatterPlotTraces(plotData);
        let layout = makeBasicPlotLayout(plotData);
        let plotPanel = document.getElementById("plot-panel");
        if (plotPanel) {
            layout.width = plotPanel.clientWidth - 10
        }
        var scaling = plotData.scaling;
        var projectId = JSON.parse(
            document.getElementById("project_id").textContent
        );
        let imageLayout = makeSelectorPlotLayout(layout);
        let slices = plotData.x.length;
        let days = slices / 24;
        let timeRange = 7;
        if (plotData.resolution === "fifteen_minutes") {
            timeRange = timeRange * 4;
        }
        var startLeft = slices - timeRange * 24;
        startLeft = startLeft - (startLeft % 24);
        let endRight = slices - 1;
        layout.xaxis.range = [plotData.x[startLeft], plotData.x[endRight]];
        layout.title = null;

        var state = {
            id: plotId,
            div: div,
            traces: traces,
            layout: layout,
            isScaled: scaling.scaled,
            profileIndex: scaling.profile_index,
            profileName: scaling.profile_name,
            validationFields: [
                "min",
                "max",
                "mean",
                "daily_variation",
                "hourly_variation",
            ],
            errors: {},
            load_profile: scaling.load_profile,
            min: scaling.min || 100,
            max: scaling.max || 2000,
            mean: scaling.mean || 1000,
            daily_variation: scaling.daily_variation || 10,
            hourly_variation: scaling.hourly_variation || 5,
            projectId: projectId,
            imageLayout: imageLayout,
            selectorBox: { left: 0, right: 100 },
            plotData: plotData,
            slices: slices,
            days: days,
            timeRange: timeRange,
            selectorImageElement: document.getElementById(
                `${plotId}-selector-image`
            ),
            containerWidth: document.getElementById(`${plotId}-container`)
                .clientWidth,
            slider: null,
            startLeft: startLeft,
            endRight: endRight,
            prefix: plotId,
            resolution: plotData.resolution,
            isMultiYear: plotData.is_multi_year,
            selectedRange: null,
        };
        return state;
    }

    function setupSliders(state) {
        // Hourly variation slider //
        /////////////////////////////
        var hourlyVariationInput = document.getElementById(
            "id_hourly_variation"
        ) as HTMLInputElement;

        var hourlyVariationSliderValue = document.getElementById(
            "hourly-variation-slider-value"
        ) as HTMLInputElement;
        hourlyVariationSliderValue.innerHTML = hourlyVariationSliderValue.value;

        var hourlyVariationSlider = document.getElementById(
            "hourly-variation-slider"
        ) as noUiSlider.Instance;
        noUiSlider.create(hourlyVariationSlider, {
            start: [parseFloat(hourlyVariationInput.value)],
            range: {
                min: 0,
                max: 100,
            },
            connect: [true, false],
            step: 1,
            tooltips: true,
            format: {
                // 'to' the formatted value. Receives a number and returns a string.
                to: function (value) {
                    return value + "%";
                },
                // 'from' the formatted value.
                // Receives a string and returns a number.
                from: function (value) {
                    return parseInt(value);
                },
            },
        });

        var hourlyVariationSliderValue = document.getElementById(
            "hourly-variation-slider-value"
        ) as HTMLInputElement;

        hourlyVariationSlider.noUiSlider.on("update", function (
            values,
            handle
        ) {
            hourlyVariationSliderValue.innerHTML = values[handle];
        });

        var hourlyVariationInput = document.getElementById(
            "id_hourly_variation"
        ) as HTMLInputElement;

        hourlyVariationSlider.noUiSlider.on("update", function (
            values,
            handle
        ) {
            hourlyVariationInput.value = values[handle].slice(0, -1);
            triggerEvent(hourlyVariationInput, "keyup");
        });

        // Daily variation slider //
        ////////////////////////////

        var dailyVariationInput = document.getElementById(
            "id_daily_variation"
        ) as HTMLInputElement;

        var dailyVariationSliderValue = document.getElementById(
            "daily-variation-slider-value"
        ) as HTMLInputElement;
        dailyVariationSliderValue.innerHTML = dailyVariationSliderValue.value;

        var dailyVariationSlider = document.getElementById(
            "daily-variation-slider"
        ) as noUiSlider.Instance;
        noUiSlider.create(dailyVariationSlider, {
            start: [parseFloat(dailyVariationInput.value)],
            range: {
                min: 0,
                max: 100,
            },
            connect: [true, false],
            step: 1,
            tooltips: true,
            format: {
                // 'to' the formatted value. Receives a number and returns a string.
                to: function (value) {
                    return value + "%";
                },
                // 'from' the formatted value.
                // Receives a string and returns a number.
                from: function (value) {
                    return parseInt(value);
                },
            },
        });

        dailyVariationSlider.noUiSlider.on("update", function (values, handle) {
            dailyVariationSliderValue.innerHTML = values[handle];
        });

        var dailyVariationInput = document.getElementById(
            "id_daily_variation"
        ) as HTMLInputElement;

        dailyVariationSlider.noUiSlider.on("update", function (values, handle) {
            dailyVariationInput.value = values[handle].slice(0, -1);
            triggerEvent(dailyVariationInput, "keyup");
        });

        state.hourlyVariationSlider = hourlyVariationSlider;
        state.dailyVariationSlider = dailyVariationSlider;

        if (!state.isScaled) {
            state.dailyVariationSlider.setAttribute("disabled", true);
            state.hourlyVariationSlider.setAttribute("disabled", true);
            Array.from(document.getElementsByClassName("noUi-base")).forEach(
                (sliderLine) => {
                    sliderLine.classList.add("slider-line-disabled");
                }
            );
            Array.from(document.getElementsByClassName("noUi-connect")).forEach(
                (sliderLine) => {
                    sliderLine.classList.add("slider-line-disabled");
                }
            );
        }
    }

    function toggleUI(state, loadProfileSelect) {
        state.profileIndex = loadProfileSelect.selectedIndex;
        state.profileName =
            loadProfileSelect.options[
                loadProfileSelect.selectedIndex
            ].innerHTML;
        state.load_profile = loadProfileSelect.value;
        if (loadProfileSelect.selectedIndex === 0) {
            let scaledInput = document.getElementById(
                "id_scaled"
            ) as HTMLInputElement;
            scaledInput.checked = false;
            scaledInput.value = "False";
            state.isScaled = false;
            [
                "id_min",
                "id_max",
                "id_mean",
                "id_daily_variation",
                "id_hourly_variation",
            ].forEach((fieldId) => {
                let el = document.getElementById(fieldId) as HTMLInputElement;
                el.disabled = true;
            });
            state.dailyVariationSlider.setAttribute("disabled", true);
            state.hourlyVariationSlider.setAttribute("disabled", true);
            Array.from(document.getElementsByClassName("noUi-base")).forEach(
                (sliderLine) => {
                    sliderLine.classList.add("slider-line-disabled");
                }
            );
            Array.from(document.getElementsByClassName("noUi-connect")).forEach(
                (sliderLine) => {
                    sliderLine.classList.add("slider-line-disabled");
                }
            );
        } else {
            let scaledInput = document.getElementById(
                "id_scaled"
            ) as HTMLInputElement;
            scaledInput.checked = true;
            scaledInput.value = "True";
            state.isScaled = true;
            [
                "id_min",
                "id_max",
                "id_mean",
                "id_daily_variation",
                "id_hourly_variation",
            ].forEach((fieldId) => {
                let el = document.getElementById(fieldId) as HTMLInputElement;
                el.disabled = false;
            });
            state.dailyVariationSlider.removeAttribute("disabled");
            state.hourlyVariationSlider.removeAttribute("disabled");
            Array.from(document.getElementsByClassName("noUi-base")).forEach(
                (sliderLine) => {
                    sliderLine.classList.remove("slider-line-disabled");
                }
            );
            Array.from(document.getElementsByClassName("noUi-connect")).forEach(
                (sliderLine) => {
                    sliderLine.classList.remove("slider-line-disabled");
                }
            );
        }
        update(state);
    }

    function setupTriggers(state) {
        document.getElementById("id_load_profile").onchange = function () {
            let loadProfileSelect = this as HTMLSelectElement;
            toggleUI(state, loadProfileSelect);
        };

        document.getElementById("id_scaled").onchange = function () {
            let el = this as HTMLInputElement;
            state.isScaled = el.checked;
            if (!state.isScaled) {
                resetParams();
            }
            update(state);
        };

        document.getElementById("reset-button").onclick = function (event) {
            event.preventDefault();
            state = reset(state);
            update(state);
        };

        [
            "id_min",
            "id_max",
            "id_mean",
            "id_daily_variation",
            "id_hourly_variation",
        ].forEach((fieldId) => {
            var el = document.getElementById(fieldId) as HTMLInputElement;
            var fieldName = fieldId.slice(3);
            el.onkeyup = debounce(function () {
                state[fieldName] = parseFloat(el.value);
                update(state);
            }, 500);
            el.onpaste = function () {
                state[fieldName] = parseFloat(el.value);
                update(state);
            };
        });
    }

    async function init() {
        let state = await getInitialState();
        renderPlot(state);
        setupSliders(state);
        setupTriggers(state);
        setUpRangeSelector(state);
        enablePlotDownloads(state);

        // Some housekeeping...
        Array.from(document.getElementsByClassName("errorlist")).forEach(
            (el) => {
                el.remove();
            }
        );

        return state;
    }

    function reset(state) {
        // Retain elements that have been added to the state while restoring the
        // original values.
        state = Object.assign(state, getInitialState());
        var selectedProfile = document.getElementById(
            "id_load_profile"
        ) as HTMLInputElement;
        selectedProfile.value = state.load_profile;
        [
            "id_min",
            "id_max",
            "id_mean",
            "id_daily_variation",
            "id_hourly_variation",
        ].forEach((fieldId) => {
            var el = document.getElementById(fieldId) as HTMLInputElement;
            var fieldName = fieldId.slice(3);
            el.value = state[fieldName];
        });
        toggleUI(state, selectedProfile);
        return state;
    }

    function update(state) {
        Promise.resolve(state)
            .then(validateFields)
            .then(displayErrors)
            .then(getTrace)
            .then(updatePlot);
    }

    // Update functions //
    //////////////////////

    const validateFields = (state) =>
        new Promise(function (resolve, reject) {
            interface Errors {
                [x: string]: any;
            }
            var errors: Errors = {};
            if (state.isScaled) {
                state.validationFields.forEach((field) => {
                    if (
                        ["min", "max", "mean"].includes(field) &&
                        !(state[field] > 0)
                    ) {
                        var error_msg = "Must be a positive number";
                        errors[field] = error_msg;
                    }
                    if (
                        ["daily_variation", "hourly_variation"].includes(
                            field
                        ) &&
                        !(state[field] >= 0)
                    ) {
                        var error_msg =
                            "Must be a greater than or equal to zero";
                        errors[field] = error_msg;
                    }
                    if (field === "mean" && state.min >= state.mean) {
                        var error_msg =
                            "Average load must be greater than base load and less than peak load";
                        errors.mean = error_msg;
                    }
                    if (field == "max") {
                        if (state.min >= state.max) {
                            var error_msg =
                                "Peak load must be greater than base and average loads";
                            errors.max = error_msg;
                        }
                        if (state.mean >= state.max) {
                            var error_msg =
                                "Peak load must be greater than base and average loads";
                            errors.max = error_msg;
                        }
                    }
                });
            }
            state.errors = errors;
            resolve(state);
        });

    const displayErrors = (state) =>
        new Promise(function (resolve, reject) {
            state.validationFields.forEach((field) => {
                var el = document.getElementById("id_" + field);
                var invalid_feedback = document.getElementById(
                    field + "-errors"
                );
                if (invalid_feedback) {
                    invalid_feedback.remove();
                }
                var error_msg = state.errors[field];
                if (typeof error_msg === "string") {
                    invalid_feedback = document.createElement("DIV");
                    invalid_feedback.id = field + "-errors";
                    invalid_feedback.classList.add("invalid-feedback");
                    invalid_feedback.innerHTML = error_msg;
                    el.classList.add("is-invalid");
                    el.parentElement.appendChild(invalid_feedback);
                } else {
                    el.classList.remove("is-invalid");
                }
            });
            resolve(state);
        });

    const getTrace = (state) =>
        new Promise(function (resolve, reject) {
            if (!Object.entries(state.errors).length) {
                let baseUrl = "/api/v1/inputs";
                let parameters = new URLSearchParams({
                    project_id: state.projectId,
                    load_profile_id: state.load_profile,
                    scaled: state.isScaled,
                    load_min: state.min,
                    load_max: state.max,
                    load_mean: state.mean,
                    daily_variation: state.daily_variation,
                    hourly_variation: state.hourly_variation,
                    profile_name: state.profileName,
                })
                let url = `${baseUrl}?${parameters}`
                fetch(url)
                    .then((response) => response.json())
                    .then((result) => {
                        state.traces = makeScatterPlotTraces(result);
                        resolve(state);
                    })
                    .catch((error) => {
                        console.log("Unable to get updated chart data");
                        console.log(error);
                    });
            } else {
                resolve(state);
            }
        });

    const updatePlot = (state) =>
        new Promise(function (resolve, reject) {
            renderPlot(state);
            renderRangeSelectorSliderImage(state);
            resolve(state);
        });

    // Helpers //
    /////////////

    function resetParams() {
        [
            "id_min",
            "id_max",
            "id_mean",
            "id_daily_variation",
            "id_hourly_variation",
        ].forEach((fieldId) => {
            var el = document.getElementById(fieldId) as HTMLInputElement;
            var fieldName = fieldId.slice(3);
            el.value = "";
            state[fieldName] = parseFloat(el.value);
        });
    }

    init();
}

/*
* Automatically disable any upload button if a file has been de-selected.
*/
function disableUploadButtonsIfNoFileIsSelected() {
    $('.fileinput').closest('form').find(":file").on("change", function() {
        let fileInput = $(this);
        let submitButton = fileInput.closest('form').find(":submit");
        if (fileInput.attr("name")) {
            submitButton.prop("disabled", false);
        } else {
            submitButton.prop("disabled", true);
        }
    });
}


/**
* Toggles visibility of field groups.
*
* Plots that were intially rendered in hidden divs must be resized; otherwise, they will be too small.
*/
function enableToggleGroups() {
    $('.group-collapse').on('show.bs.collapse', function () {
        $(this).siblings('.group-heading').addClass('active');
        $(this).find("*[id$='-plot']").each(function (i, el) {
            Plotly.Plots.resize(el)
        });
        $(this).find("*[id$='-map']").each(function (i, el) {
            setTimeout(function () {
                state.maps[el.id].invalidateSize();
             }, 10);
        });
    });
    
    $('.group-collapse').on('hide.bs.collapse', function () {
        $(this).siblings('.group-heading').removeClass('active');
    });
}


/**
* Applies a "count up" animation to all p elements whose id's end with "countup-value".
*/
function enableCountUp() {
    $("span[id$='countup-value']").each(function (i, el) {
        let options = {
            separator: ",",
            decimal: ".",
            decimalPlaces: 0,
        };
        let _value = el.dataset.value;
        if (_value) {
            let parts = _value.split(".");
            let value = parts.length === 2 ? parseFloat(_value) : parseInt(_value);
            options.decimalPlaces = parts.length === 2 ? parts[1].length : 0;
            new CountUp(
                el.id,
                value,
                options
            ).start();
        }
     });
}

/**
* Add a map showing the project's location; if the map is part of a form, 
* the coordinates are derived from the location fields and the map reacts
* to changes in those fields.
*/
function makeProjectLocationMap() {
    let mapDiv = document.getElementById("project-location-map")
    if (!mapDiv) {
        return;
    }
    let isReactive = mapDiv.classList.contains("reactive-map");
    let isReportMap = mapDiv.classList.contains("report-map");
    let coordinates: Array<number>
    function parseFloatOrZero(coord) {
        let elementId = `id_${coord}`
        let element = document.getElementById(elementId)
        let value = element.value
        let parsedFloat = parseFloat(value);
        let isValid = true;
        if (Number.isNaN(parsedFloat)) {
            isValid = false;
        } else if (coord == "north") {
            if (parsedFloat > 90 || parsedFloat < 0) {
                isValid = false;                }
        } else if (coord == "south") {
            if (parsedFloat > 90 || parsedFloat < 0) {
                isValid = false;                }
        } else if (coord == "east") {
            if (parsedFloat > 180 || parsedFloat < 0) {
                isValid = false;                }
        } else if (coord == "west") {
            if (parsedFloat > 180 || parsedFloat < 0) {
                isValid = false;                }
        } else {
            isValid = false;            }
        if (isValid) {
            element.parentElement.classList.remove("has-danger")
        } else {
            element.parentElement.classList.add("has-danger")
        }
        return parsedFloat
    }
    if (isReactive) {
        let north = parseFloatOrZero("north")
        let south = parseFloatOrZero("south")
        let east = parseFloatOrZero("east")
        let west = parseFloatOrZero("west")
        let latitude = north - south
        let longitude = east - west
        coordinates = [latitude, longitude]
        for (let field of ["north", "south", "east", "west"]) {
            document.getElementById(`id_${field}`).onkeyup = function () {
                updateMap()
            };
        }
        for (let field of ["north", "south", "east", "west"]) {
            document.getElementById(`id_${field}`).onchange = function () {
                updateMap()
            };
        }
    } else {
        try {
            coordinates = JSON.parse(
                document.getElementById("project-location-coordinates").textContent
            );
        } catch {
            coordinates = [52.520008, 13.404954]
        }
    }
    let map = L.map(mapDiv, {zoomControl: !isReportMap}).setView(coordinates, 13);
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '© OpenStreetMap'
    }).addTo(map);
    let marker = L.marker(coordinates).addTo(map);
    function updateMap() {
        let isValid = true;
        let north = parseFloatOrZero("north")
        let south = parseFloatOrZero("south")
        let east = parseFloatOrZero("east")
        let west = parseFloatOrZero("west")
        let latitude = north - south
        if (Math.abs(latitude) > 90 ) {
            isValid = false;
        }
        let longitude = east - west
        if (Math.abs(longitude) > 180) {
            isValid = false;
        }
        if (isValid) {
            coordinates = [latitude, longitude]
            try {
                map.panTo(coordinates)
                marker.setLatLng(coordinates)
            } catch {
                console.log("invalid coordinates")
            }
        }
    }
}

function resizeLocationFields() {
    let mapElement = document.getElementById("project-location-map");
    if (!mapElement) {
        return;
    }
    function resize() {
        let element = document.getElementById("location-row");
        let labels = Array.from(document.getElementsByClassName("location-label"))
        let inputs = Array.from(document.getElementsByClassName("location-input"))
        if (window.innerWidth < 900) {
            element.classList.remove("row");
            element.style.columnGap = "0px"
            for (let label of labels) {
                label.classList.add("col-md-3")
                label.classList.remove("col-md-6")
            }
            for (let input of inputs) {
                input.classList.add("col-md-9")
                input.classList.remove("col-md-6")
            }
            mapElement.style.marginBottom = "20px";
        } else {
            element.classList.add("row");
            element.style.columnGap = "15px"
            for (let label of labels) {
                label.classList.add("col-md-6")
                label.classList.remove("col-md-3")
            }
            for (let input of inputs) {
                input.classList.add("col-md-6")
                input.classList.remove("col-md-9")
            }
            mapElement.style.marginBottom = "10px";
        }
    }
    resize();
    window.addEventListener("resize", resize);
}

async function checkResDownloadStatus() {
    let configElement = document.getElementById("res-status-config") as HTMLScriptElement;
    if (!configElement) {
        return
    }
    let config = JSON.parse(configElement.textContent);
    if (!config.wait) {
        return
    }
    let url = `${apiUrl}/projects/${config.project_id}/inputs/res_download_status`;
    let status;
    while (true) {
        console.log("polling")
        let response = await get(url);
        let result = await response.json();
        status = result.status;
        if (status !== "in_progress") {
            break;
        }
        await sleep(1500);
    }
    window.location.href = `/projects/${config.project_id}/demand/preview`
}


/*
* Add functionality for adding or removing formset rows for "matrix" formsets within project
* or component forms by clicking an add or subtract button.
*/
function enhanceMatrixFields() {
    let chartDivs = Array.from(document.querySelectorAll("[id$='-component-chart']"))
    for (let chartDiv of chartDivs) {
        let chartName = chartDiv.id.replace("-component-chart", "");
        let seriesLabels = [];
        let formsetHeaderDiv = Array.from(chartDiv.parentElement.querySelectorAll("[class$='formset-row-header']"))[0]
        for (let child of Array.from(formsetHeaderDiv.children)) {
            seriesLabels.push(child.innerText.replace(/(^\s+)|(\s+$)|(\r\n|\n|\r)/gm,""));
        }
        let yAxisLabels = [];
        for (let seriesLabel of seriesLabels.slice(1)) {
            let unit = seriesLabel.match(/\[(.*)\]/)
            if (unit) {
                unit = unit[0]
            } else {
                unit = "quantity"
            }
            if (!yAxisLabels.includes(unit)) {
                yAxisLabels.push(unit)
            }
        }
        let layout = makeTimeSeriesLayout("", false, seriesLabels[0], yAxisLabels)

        function getTraces(chartDiv, seriesLabels) {
            let series = [];
            for (let i = 0; i < seriesLabels.length; i++) {
                series.push([])
            }
            let rowFields = Array.from(chartDiv.parentElement.querySelectorAll("[class$='formset-row-fields']"))
            for (let rowField of rowFields) {
                let cols = Array.from(rowField.querySelectorAll("[class^='col-']"))
                for (let [i, col] of cols.entries()) {
                    let value = col.querySelector("input").value
                    series[i].push(parseFloat(value))
                }
            }
            let xValues = series[0];
            let traces = []
            for (let [i, seriex] of series.slice(1).entries()) {
                let trace = {
                    x: xValues,
                    y: seriex,
                    mode: "lines",
                    name: seriesLabels[i + 1],
                    opacity: 0.9,
                }
                traces.push(trace)
            }
            return traces
        }

        function updateChart() {
            let traces = getTraces(chartDiv, seriesLabels);
            Plotly.react(chartDiv.id, traces, layout, {
                displayModeBar: false,
                displaylogo: false,
                responsive: true,
            });
        }

        updateChart();

        let rowFields = Array.from(chartDiv.parentElement.querySelectorAll("[class$='formset-row-fields']"))
        for (let rowField of rowFields) {
            let cols = Array.from(rowField.querySelectorAll("[class^='col-']"))
            for (let [i, col] of cols.entries()) {
                let input = col.querySelector("input");
                input.addEventListener("keyup", function() {
                    updateChart();
                })
            }
        }
        
        let formsetRows = chartDiv.parentElement.querySelector(".formset-rows")
        let addButton = document.createElement("button");
        addButton.innerHTML = '<i class="tim-icons icon-simple-add"></i>';
        addButton.classList.add("btn", "btn-icon", "btn-round", "btn-success")
        addButton.style.marginLeft = "1px"
        let removeButton = document.createElement("button");
        removeButton.innerHTML = '<i class="tim-icons icon-simple-delete"></i>';
        removeButton.classList.add("btn", "btn-icon", "btn-round", "btn-danger")
        let totalFormsEl = formsetRows.firstElementChild;
        addButton.addEventListener("click", function(event) {
            event.preventDefault()
            let prevDiv = $(addButton).parent().prev()
            let copyDiv = $(prevDiv).clone()
            $(copyDiv).children().children().each(function() {
                let parts = this.name.split("-");
                parts[1] = `${parseInt(parts[1]) + 1}`
                let name = parts.join("-")
                this.name = name
                this.id = `id_${name}`
                this.value = ""
            })
            totalFormsEl.value = `${parseInt(totalFormsEl.value) + 1}`
            $(copyDiv).insertAfter(prevDiv)
            let lastRow = Array.from(chartDiv.parentElement.querySelectorAll("[class$='formset-row-fields']")).slice(-1)[0]
            let cols = Array.from(lastRow.querySelectorAll("[class^='col-']"))
            for (let [i, col] of cols.entries()) {
                let input = col.querySelector("input");
                input.addEventListener("keyup", function() {
                    updateChart();
                })
            }
        });
        removeButton.addEventListener("click", function(event) {
            event.preventDefault()
            let prevDiv = $(addButton).parent().prev()
            if (prevDiv.prev().hasClass("formset-row-fields")) {
                prevDiv.remove();
                totalFormsEl.value = `${parseInt(totalFormsEl.value) - 1}`
            }
            updateChart();
        });
        let div = document.createElement("div");
        div.style.textAlign = "center"
        div.append(addButton);
        div.append(removeButton);
        formsetRows.append(div)        
    }
    
}

/*
* Add functionality for adding or removing formset rows for "matrix" formsets within project
* or component forms by clicking an add or subtract button.
*/
function enhanceWindPowerCurveFields() {
    let chartDiv = document.querySelector("#wind-power-curve-chart")
    if (chartDiv) {
        let layout = makeTimeSeriesLayout("", false,"Windspeed [m/s]", ["Output [kW]"])

        function getTraces(chartDiv) {
            let xValues = [];
            let yValues = [];
            let inputs = Array.from(chartDiv.parentElement.parentElement.parentElement.querySelectorAll("[id^='id_pc']"))
            for (let [i, input] of inputs.entries()) {
                let value = parseFloat(input.value)
                xValues.push(i + 1);
                yValues.push(value);
            }
            let trace = {
                x: xValues,
                y: yValues,
                mode: "lines",
                name: "Output [kW]",
                opacity: 0.9,
            }            

            return [trace]
        }

        function updateChart() {
            let traces = getTraces(chartDiv);
            Plotly.react(chartDiv.id, traces, layout, {
                displayModeBar: false,
                displaylogo: false,
                responsive: true,
            });
        }

        updateChart();

        let inputs = Array.from(chartDiv.parentElement.parentElement.parentElement.querySelectorAll("[id^='id_pc']"))
        for (let input of inputs) {
            input.addEventListener("keyup", function() {
                updateChart();
            })
        }
    }
}

function validateMinMaxBase() {
    let isAssetFormRow = false;
    let minMaxRows = document.getElementsByClassName("min-max-row")
    if (!minMaxRows.length) {
        minMaxRows = document.getElementsByClassName("asset-form-row")
        isAssetFormRow = true;
    }
    if (!minMaxRows) {
        return;
    }
    let updateAssetValuesButton = document.getElementById("update-asset-values-button");
    for (let minMaxRow of minMaxRows) {
        let assetId = (isAssetFormRow) ? minMaxRow.dataset.componentId : minMaxRow.dataset.asset_id;
        let banner = document.getElementById("min-max-feedback-banner")
        if (isAssetFormRow) {
            banner = minMaxRow.parentElement.parentElement.parentElement.parentElement.firstElementChild
        }
        let minEl = document.getElementById(`min-${assetId}`)
        let maxEl = document.getElementById(`max-${assetId}`)
        minEl.onkeyup = function () {
            let minVal = parseInt(this.value);
            let maxVal = parseInt(document.getElementById(`max-${assetId}`).value)
            if (minVal > maxVal) {
                updateAssetValuesButton.disabled = true;
                minEl.classList.add("input-field-error")
                banner.innerHTML = "Min cannot be greater than max"
                banner.classList.remove("d-none")
            } else {
                updateAssetValuesButton.disabled = false;
                minEl.classList.remove("input-field-error")
                maxEl.classList.remove("input-field-error")
                banner.classList.add("d-none")
            }
        };
        maxEl.onkeyup = function () {
            let maxVal = parseInt(this.value);
            let minVal = parseInt(document.getElementById(`min-${assetId}`).value)
            if (maxVal < minVal) {
                updateAssetValuesButton.disabled = true;
                maxEl.classList.add("input-field-error")
                banner.innerHTML = "Max cannot be less than min"
                banner.classList.remove("d-none")
            } else {
                updateAssetValuesButton.disabled = false;
                maxEl.classList.remove("input-field-error")
                minEl.classList.remove("input-field-error")
                banner.classList.add("d-none")
            }
        };
    }
}

function warnResultsDownload() {
    let resultsButton = document.getElementById("results-btn");
    if (!resultsButton) {
        return
    }
    resultsButton.onclick = function (e) {
        Swal.fire({
            position: "center",
            title: "Downloading results file!",
            text: "This may take a few moments...",
            showConfirmButton: false,
            timer: 2000,
            icon: "success",
        });
    };
}

window["manageJobs"] = manageJobs;
window["cancelJob"] = cancelJob;
window["startJob"] = startJob;
window["loadGeneratorPlot"] = loadGeneratorPlot;

/*
* Initialize the module.
*/
function init() {
    resizeLocationFields();
    document.addEventListener("DOMContentLoaded", function(){
        enableCountUp();
        warnResultsDownload();
        makePlots();
        enableToggleGroups();
        enhanceMatrixFields();
        enhanceWindPowerCurveFields();
        disableUploadButtonsIfNoFileIsSelected();
        makeProjectLocationMap();
        validateMinMaxBase();
        checkResDownloadStatus();
    });
    // Enable tooltips
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
      })
}

init();
